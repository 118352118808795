import React from 'react'
import {Link} from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CoolHeader, {SectionHeader} from '../components/Elements/CoolHeader'

const JustPublished = () => (
  <Layout>
    <SEO customTitle="You're Published" />
    <CoolHeader>Congrats!</CoolHeader>
    <p>We're so lucky to have you in Fernweh</p>
    <CoolHeader>Fernweh The Journal</CoolHeader>
  </Layout>
)

export default JustPublished
